.bt-forms-recaptcha {
  margin: auto;
  width: 50%;

  > div {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
