// Typography


$base-font-family: 'Open Sans', sans-serif;
$heading-font-family: $base-font-family;

// Font Sizes
$base-font-size: 1em;
$base-font-weight: 400;
$h1-font-size: 1.875em;
$h2-font-size: 1.375em;
$h3-font-size: 1.188em;
$h4-font-size: 1.125em;
$h5-font-size: 1em;
$h6-font-size: 0.875em;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

// Colors
$blue: #1cafe9;
$dark-gray: #242424;
$medium-gray: #555;
$light-gray: #f8f8f8;

// Font Colors
$base-background-color: #efefef;
$base-font-color: $dark-gray;
$action-color: $blue;

// Border
$base-border-color: #fff;
$base-border: 0;

// Forms
$forms-line-height: 1.5;
$form-box-shadow: inset 0 1px 3px rgba(#000, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px adjust-color($action-color, $lightness: -5%, $alpha: -0.3);
$label-font-color: #676767;
