.flex-boxes {
  ///////////////////////////////////////////////////////////////////////////////////
  $base-border-radius: 3px !default;
  $base-background-color: white !default;
  $dark-gray: #333 !default;
  $light-gray: #DDD !default;

  a {
    text-decoration: none;
  }
  //////////////////////////////////////////////////////////////////////////////////

  $flex-box-border-color: $light-gray;
  $flex-box-border: 1px solid $flex-box-border-color;
  $flex-box-background: lighten($light-gray, 10%);
  $flex-box-gutter: 0.4em;
  $flex-box-width: 18em;
  $flex-box-color: transparentize($base-font-color, 0.3);
  $flex-box-top-colors:
  $blue,
  $dark-gray,
  $medium-gray,
  $light-gray,
  $blue;

  @include clearfix;
  @include display(flex);
  @include justify-content(center);
  @include align-items(stretch);
  @include flex-wrap(wrap);
  clear: both;
  text-align: center;

  .flex-box {
    @include flex(2 2 15em);
    @include align-self(stretch);
    background: $flex-box-background;
    border-radius: $base-border-radius;
    box-shadow: inset 0 0 1px $flex-box-border-color, 0 2px 4px darken($base-background-color, 10%);
    display: block;
    margin: $flex-box-gutter;
    padding: 2em 2em 3em 2em;
    border-top: 6px solid $blue;
    //@for $i from 1 to 6 { // assign colors from $flex-box-top-colors list
    //  &:nth-child(#{$i}) {
    //    $color-from-list: nth($flex-box-top-colors, $i);
    //    border-top: 6px solid $color-from-list;
    //  }
    //}

    img {
      display: block;
      height: 3em;
      margin-bottom: 2em;
      margin: auto;
      opacity: 0.4;
    }

    .flex-title {
      color: $flex-box-color;
      font-size: 1.2em;
      font-weight: 800;
      margin-bottom: 0.5em;
    }

    p {
      line-height: 1.5em;
      margin: auto;
    }
  }

  .flex-box-big {
    @include flex(1 1 40em);
  }
}
.flex-front {
  display:none;
}